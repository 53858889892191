@import "./palettes.scss";
@import "./keyframes.scss";

@mixin SunshineTime {
    --foreground: #000000;
    --background: #ffffff;

    @include SunshinePalette;
}

@mixin ACertainKindOfDarknessIsNeededToSeeTheStarsSometimes {
    --foreground: #ffffff;
    --background: #000000;
    @include NightPalette;
}

:root {
    --foreground: #ffffff;
    --background: #000000;

    --accent: #557200;
    --accent-dark: #425800;
    --accent-darker: #354600;
    --accent-darkest: #212c00;
    --accent-light: #afc278;
    --highlight-color: #b2ca6964;

    --accent-1-light: #c0f3ff;
    --accent-2: #c398f7;

    --leaf-entry-anim-duration: 2s;
    --padding: 40px;

    @media (prefers-color-scheme: dark) {
        @include ACertainKindOfDarknessIsNeededToSeeTheStarsSometimes();
    }

    @media (prefers-color-scheme: light) {
        @include SunshineTime();
    }

    @media (max-width: 480px) {
        --padding: 30px;
    }
}

.sunshine-time {
    @include SunshineTime();
}

.a-certain-kind-of-darkness-is-needed-to-see-the-stars-sometimes {
    @include ACertainKindOfDarknessIsNeededToSeeTheStarsSometimes();
}

body {
    margin: 0;
    font-family: sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    font-family: "Quicksand", sans-serif;
    color: var(--foreground);
    background: var(--background);
    height: 100%;
    width: 100vw;
    transition: background 0.4s;
}

* {
    -webkit-tap-highlight-color: var(--highlight-color);
    margin: 0;
}

h1,
h2,
h3,
.heading {
    font-family: "Crimson Text", serif;
    font-weight: lighter;
    text-transform: uppercase;
    text-align: center;
    color: var(--accent-light);
}

h1,
.heading {
    --font-size: 3vh;
    font-size: var(--font-size);
    letter-spacing: calc(var(--font-size) / 3);
    line-height: var(--font-size);
}

*::selection {
    background: var(--highlight-color);
}

.home-is-where-your-dev-machine-is {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100vw;
    overflow: hidden;
    z-index: 1;
    --fill: var(--accent-darker);
    --stroke: var(--accent-dark);
}
