@keyframes slideDown {
    100% {
        transform: translateY(0%);
    }
}
// leaves rustling in the breeze
@keyframes rustle {
    100% {
        transform: rotate(var(--rotate, 0deg));
    }
}

.leaf-me-be {
    --fill: var(--accent-darker);
    --stroke: var(--accent-dark);
    pointer-events: none;

    svg,
    path,
    g {
        -webkit-tap-highlight-color: transparent;
    }
    svg {
        fill: none;
        overflow: visible;
        height: 100%;
    }

    /* Only do this for non mobile, 
	causes stutter on mobile with other anim*/
    @media (hover: hover) {
        transform: translateY(-100%);
        animation: slideDown var(--leaf-entry-anim-duration) forwards
            cubic-bezier(0.45, 0, 0.55, 1);
    }
}

path {
    pointer-events: all;
}

path.stem {
    pointer-events: none;
}

.leaf-stem,
.leaf-fill,
.leaf-stroke,
.stem {
    transition: 1s ease fill, 1s ease stroke;
}

.leaf-group {
    cursor: pointer;

    @media (hover: hover) {
        &:hover {
            --fill: var(--accent-dark);
            --stroke: var(--accent);

            .leaf-outline-fill {
                transform: rotate(var(--rotate, 0deg));
            }
        }
    }
}

.leaf-outline-fill {
    transition: all 1s ease;

    // for both, turn brown and stop animations
    &.dead {
        --fill: #1c1917;
        --stroke: #2a241f;
        animation: none;
        cursor: default;

        path {
            transition: fill 5s 2s, stroke 5s 2s;
        }
    }

    @media (hover: hover) {
        // for desktop, just fall to the damn "ground"
        &.dead {
            transform: translateY(var(--distance-to-bottom, -100vh)) !important;
        }
    }

    @media (hover: none) {
        animation: rustle calc(2s * var(--index) / 3) infinite alternate-reverse
            ease-in-out both;

        // for mobile, turn brown and then fall to the "ground
        @keyframes falldown {
            100% {
                transform: translateY(var(--distance-to-bottom, -100vh));
            }
        }
        &.dead {
            animation: falldown 2s forwards 6s;
        }
    }
}

.leaf-fill {
    fill: var(--fill);
}

.leaf-stroke,
.stem,
.leaf-stem {
    stroke: var(--stroke);
}

@for $i from 1 through 13 {
    .leaf-#{$i} {
        --index: #{$i};
    }
}

.leaf-1 {
    --rotate: -14deg;
    .leaf-outline-fill {
        transform-origin: 62% 12%;
    }
}

.leaf-2 {
    --rotate: -14deg;
    .leaf-outline-fill {
        transform-origin: 50% 25%;
    }
}

.leaf-3 {
    --rotate: 23deg;
    .leaf-outline-fill {
        transform-origin: 63% 27%;
    }
}

.leaf-4 {
    --rotate: -27deg;
    .leaf-outline-fill {
        transform-origin: 44% 38%;
    }
}

.leaf-5 {
    --rotate: 23deg;
    .leaf-outline-fill {
        transform-origin: 54% 38%;
    }
}

.leaf-6 {
    --rotate: -27deg;
    .leaf-outline-fill {
        transform-origin: 31% 53%;
    }
}

.leaf-7 {
    --rotate: 10deg;
    .leaf-outline-fill {
        transform-origin: 39% 50%;
    }
}

.leaf-8 {
    --rotate: -17deg;
    .leaf-outline-fill {
        transform-origin: 32% 66%;
    }
}

.leaf-9 {
    --rotate: 26deg;
    .leaf-outline-fill {
        transform-origin: 48% 65%;
    }
}

.leaf-10 {
    --rotate: -6deg;
    .leaf-outline-fill {
        transform-origin: 48% 80%;
    }
}

.leaf-11 {
    --rotate: 14deg;
    .leaf-outline-fill {
        transform-origin: 64% 79%;
    }
}

.leaf-12 {
    --rotate: -39deg;
    .leaf-outline-fill {
        transform-origin: 52% 90%;
        transition: 2s;
    }
}

.leaf-13 {
    --rotate: 24deg;
    .leaf-outline-fill {
        transform-origin: 66% 94%;
    }
}
