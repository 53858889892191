@import "../../styles/palettes.scss";

.container {
    position: relative;
    height: 100%;

    svg {
        position: absolute;
    }
    .sunshine {
        transition: 1s all;
    }
    .moonlight {
        --deg: 180deg;
        transition: 0.5s all;
    }

    // override the hover on the links
    @media (hover: hover) {
        &:hover {
            svg {
                fill: none !important;
                transform: rotate(var(--deg, 360deg)) !important;
            }
        }

        &.sunshine-time:hover {
            @include SunshinePalette;
        }

        &.night-time:hover {
            @include NightPalette;
        }
    }
}

.sunshine-time {
    .moonlight {
        opacity: 0;
    }
}

.night-time {
    .sunshine {
        opacity: 0;
    }
}
