@mixin OGGreen {
    --accent: #007263;
    --accent-dark: #073634;
    --accent-darker: #062221;
    --accent-darkest: #041918;
    --accent-light: #4a968d;
}

@mixin DustyRose {
    --accent: #b275a0;
    --accent-dark: #905a7f;
    --accent-darker: #724264;
    --accent-darkest: #47273d;
    --accent-light: #d8a8c9;
}

@mixin DustyViolet {
    --accent: #83629c;
    --accent-dark: #6a4c81;
    --accent-darker: #47305a;
    --accent-darkest: #3c2b49;
    --accent-light: #a087b4;
}

@mixin VividViolet {
    --accent: #9443d1;
    --accent-dark: #7b35b0;
    --accent-darker: #622d8a;
    --accent-darkest: #441e61;
    --accent-light: #c089ea;
}

@mixin GreyTeal {
    --accent: #628e9c;
    --accent-dark: #4b7481;
    --accent-darker: #375a65;
    --accent-darkest: #294852;
    --accent-light: #81aab6;
}

@mixin Midnight {
    --accent: #0c3154;
    --accent-dark: #0b253d;
    --accent-darker: #0a1e31;
    --accent-darkest: #01101d;
    --accent-light: #3c6a95;
}

@mixin Autumn {
    --accent: #e97100;
    --accent-dark: #c3640b;
    --accent-darker: #8d4c10;
    --accent-darkest: #603106;
    --accent-light: #eca25c;
}

@mixin HotPink {
    --accent: #e900ca;
    --accent-dark: #c809ae;
    --accent-darker: #830673;
    --accent-darkest: #4b0342;
    --accent-light: #e399d9;
}

@mixin YellowFellow {
    --accent: #f3c44d;
    --accent-dark: #c48f07;
    --accent-darker: #916903;
    --accent-darkest: #624702;
    --accent-light: #edc96e;
}
@mixin Light_YellowFellow {
    @include YellowFellow();
    --accent-light: #433208;
    --p-accent: var(--accent-dark);

    --background: #fcf3dc;
}

@mixin BrightTealIThink {
    --accent: #006572;
    --accent-dark: #004f5a;
    --accent-darker: #003e46;
    --accent-darkest: #003840;
    --accent-light: #568c93;
}

@mixin SummerDreamsOfGreen {
    --accent: #557200;
    --accent-dark: #425800;
    --accent-darker: #354600;
    --accent-darkest: #212c00;
    --accent-light: #afc278;
    --highlight-color: #b2ca6964;

    --accent-1-light: #c0f3ff;
    --accent-2: #c398f7;
}
@mixin Light_SummerDreamsOfGreen {
    --accent: #557200;
    --accent-dark: #92a756;
    --accent-darker: #a0af72;
    --accent-darkest: #bbc798;
    --accent-light: #557200;
    --highlight-color: #b2ca6964;

    --accent-1-light: #c0f3ff;
    --accent-2: #511f8d;

    --background: #dde8c0;
}

@mixin NightPalette {
    @include SummerDreamsOfGreen();
}

@mixin SunshinePalette {
    @include Light_SummerDreamsOfGreen();
}
