@mixin centerAbsoluteTranslate {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin centerAbsoluteCalc {
    position: absolute;
    top: calc(50% - var(--height, 25vh) / 2);
    left: calc(50% - var(--width, 25vh) / 2);
}
