.char-container {
    position: absolute;
    height: var(--radius);
    transform-origin: 0% 100%;
    --deg: calc(360 / var(--num-char));
    transform: rotate(calc(var(--i) * var(--deg) * 1deg));
    pointer-events: none;
    transition: 0.4s all;

    &:hover {
        color: var(--accent);
    }
}

.char {
    pointer-events: all;
}

.paragraph {
    --radius: 25vh;
    --num-char: 29; // Set in the JS
}
