@import "../../styles/keyframes.scss";
@import "../../styles/mixins.scss";

.pumpkin {
    --main-radius: 25vh;
    --main-font-size: 3vh;

    @media (max-width: 60vh) {
        --main-radius: 40vw;
        --main-font-size: 5vw;
    }
}

.main-event {
    --radius: var(--main-radius);
    --font-size: var(--main-font-size);

    --height: calc(var(--radius) * 2);
    --width: calc(var(--radius) * 2);

    @include centerAbsoluteCalc();

    width: var(--width);
    height: var(--height);
    z-index: 8;

    transform-origin: 50% 50%;
    --starting-rotate: -58deg;
    transform: rotate(var(--starting-rotate));

    cursor: default;
    pointer-events: none;

    animation: spin 120s linear infinite var(--leaf-entry-anim-duration);

    // Make my name a diff color
    @for $i from 1 through 10 {
        .char-container:nth-of-type(#{$i}) {
            color: var(--foreground);

            @media (hover: hover) {
                &:hover {
                    color: var(--accent-light);
                }
            }
        }
    }
}

.magical-leaf-i-corn {
    --height: calc(var(--main-radius) * 1);
    --width: calc(var(--main-radius) * 1);
    height: var(--height);
    width: var(--width);
    @include centerAbsoluteCalc();
    z-index: 8;

    pointer-events: none;
    transform: rotate(-5deg);
    transform-origin: 50% 64%;

    animation: fadeInOut 10s both;
    transition: 1s all;

    path,
    g,
    mask {
        pointer-events: all;
    }

    &:hover {
        transform: rotate(15deg);
    }
}

.inner-circle {
    --radius: calc(var(--main-radius) * 1.08 + 2px);
    --font-size: 1vh;

    --height: calc(var(--radius) * 2);
    --width: calc(var(--radius) * 2);

    width: var(--width);
    height: var(--height);

    z-index: 8;
    animation: spin 360s linear infinite var(--leaf-entry-anim-duration) reverse;
    pointer-events: none;

    @include centerAbsoluteCalc();
}
