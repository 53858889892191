@import "./palettes.scss";

@keyframes colors {
    0% {
        @include DustyRose;
    }
    10% {
        @include HotPink;
    }
    20% {
        @include Autumn;
    }
    30% {
        @include YellowFellow;
    }
    40% {
        @include SummerDreamsOfGreen;
    }
    50% {
        @include OGGreen;
    }
    60% {
        @include Midnight;
    }
    70% {
        @include BrightTealIThink;
    }
    80% {
        @include GreyTeal;
    }
    90% {
        @include VividViolet;
    }
    100% {
        @include DustyViolet;
    }
}

@keyframes spin {
    100% {
        transform: rotate(calc(360deg + var(--starting-rotate, 0deg)));
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 100%;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 100%;
    }
    100% {
        opacity: 0%;
    }
}

@keyframes fadeInOut {
    0% {
        opacity: 0;
    }
    10% {
        opacity: 100%;
    }
    60% {
        opacity: 100%;
    }
    100% {
        opacity: 0%;
    }
}
