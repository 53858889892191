.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 30px;
    padding: var(--padding);
    text-align: left;
    z-index: 99;
    pointer-events: none;

    p {
        margin: 0;
        margin-top: 5px;
        font-size: 13px;
        color: var(--p-accent, var(--accent));
        text-transform: uppercase;
        letter-spacing: 2px;
    }

    svg {
        fill: var(--accent-dark);
        height: 100% !important;
        transition: all 0.15s;
        transform-origin: 50% 50%;
        pointer-events: all;
        margin-right: calc(var(--padding) / 2);
    }

    @media (max-width: 480px) {
        padding-bottom: 40px;
        padding-left: 20px;
    }
}

.link {
    position: relative;
    display: inline-block;
    height: 100%;
    cursor: pointer;

    @media (min-width: 768px) {
        &:hover svg {
            fill: var(--accent);
            transform: scale(1.2);
        }
    }
}
